/* eslint-disable react/prop-types */
import React from 'react'

const LoginFlowTitles = ({ title, classSelected, spanClass }) => (
  <div className={classSelected}>
    <span className={spanClass}>{ title }</span>
  </div>
)

export default LoginFlowTitles
