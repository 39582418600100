import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const usePageTracking = () => {
  const location = useLocation()

  useEffect(() => {
    window.gtag('set', 'page', location.pathname + location.search)
    window.gtag('send', 'pageview')
  }, [location])
}

export default usePageTracking
