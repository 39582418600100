/* eslint-disable import/prefer-default-export */
import { configureStore } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'
// import sessionStorage from 'redux-persist/es/storage/session' <--- ALTERNATIVE TO STORAGE
import { persistReducer, persistStore } from 'redux-persist'
import thunk from 'redux-thunk'
import mainReducer from './UserReducer'

const persistConfig = {
  key: 'root',
  storage, // WE MAY CHOOSE TO USE SESSION STORAGE INSTEAD
}

const persistedReducer = persistReducer(persistConfig, mainReducer)

export const store = configureStore({
  reducer: {
    user: persistedReducer,
  },
  middleware: [thunk],
})

export const persistor = persistStore(store)
